import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _f04d711c = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "" */))
const _4b2f90e2 = () => interopDefault(import('../pages/authorization/index.vue' /* webpackChunkName: "pages/authorization/index" */))
const _8abc3abc = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _7c5d255e = () => interopDefault(import('../pages/partner/index.vue' /* webpackChunkName: "pages/partner/index" */))
const _0d08ecff = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _702235f9 = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _557916d9 = () => interopDefault(import('../pages/request-password/index.vue' /* webpackChunkName: "pages/request-password/index" */))
const _abc67830 = () => interopDefault(import('../pages/search/index.vue' /* webpackChunkName: "pages/search/index" */))
const _5938fed2 = () => interopDefault(import('../pages/success-payment/index.vue' /* webpackChunkName: "pages/success-payment/index" */))
const _ff95a704 = () => interopDefault(import('../pages/countries/ind222ex__.vue' /* webpackChunkName: "pages/countries/ind222ex__" */))
const _3b41e93f = () => interopDefault(import('../pages/marketplace/account/index.vue' /* webpackChunkName: "pages/marketplace/account/index" */))
const _070b7abd = () => interopDefault(import('../pages/marketplace/registration/index.vue' /* webpackChunkName: "pages/marketplace/registration/index" */))
const _fca9d01a = () => interopDefault(import('../pages/partner/activations-history/index.vue' /* webpackChunkName: "pages/partner/activations-history/index" */))
const _fd1b5768 = () => interopDefault(import('../pages/partner/price-regulator/index.vue' /* webpackChunkName: "pages/partner/price-regulator/index" */))
const _1058a56d = () => interopDefault(import('../pages/partner/sim-card/index.vue' /* webpackChunkName: "pages/partner/sim-card/index" */))
const _1ac04342 = () => interopDefault(import('../pages/partner/statistic/index.vue' /* webpackChunkName: "pages/partner/statistic/index" */))
const _a2376b46 = () => interopDefault(import('../pages/partner/withdrawal-funds/index.vue' /* webpackChunkName: "pages/partner/withdrawal-funds/index" */))
const _024cef78 = () => interopDefault(import('../pages/profile/history/index.vue' /* webpackChunkName: "pages/profile/history/index" */))
const _4270ef6d = () => interopDefault(import('../pages/profile/loyalty-program/index.vue' /* webpackChunkName: "pages/profile/loyalty-program/index" */))
const _c9034450 = () => interopDefault(import('../pages/profile/pay/index.vue' /* webpackChunkName: "pages/profile/pay/index" */))
const _09f9307a = () => interopDefault(import('../pages/profile/referral-program/index.vue' /* webpackChunkName: "pages/profile/referral-program/index" */))
const _ff175a2c = () => interopDefault(import('../pages/profile/sale-agreement/index.vue' /* webpackChunkName: "pages/profile/sale-agreement/index" */))
const _69fb6089 = () => interopDefault(import('../pages/profile/settings/index.vue' /* webpackChunkName: "pages/profile/settings/index" */))
const _4247fec9 = () => interopDefault(import('../pages/profile/statistics/index.vue' /* webpackChunkName: "pages/profile/statistics/index" */))
const _6f92c1bf = () => interopDefault(import('../pages/profile/tariffs/index.vue' /* webpackChunkName: "pages/profile/tariffs/index" */))
const _b9df0756 = () => interopDefault(import('../pages/signup/confirm-email.vue' /* webpackChunkName: "pages/signup/confirm-email" */))
const _4c3b72a9 = () => interopDefault(import('../pages/marketplace/account/adding-product/index.vue' /* webpackChunkName: "pages/marketplace/account/adding-product/index" */))
const _92aed668 = () => interopDefault(import('../pages/marketplace/account/balance/index.vue' /* webpackChunkName: "pages/marketplace/account/balance/index" */))
const _873aee28 = () => interopDefault(import('../pages/marketplace/registration/originalIndex.vue' /* webpackChunkName: "pages/marketplace/registration/originalIndex" */))
const _8e297d4c = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _492b07c7 = () => interopDefault(import('../pages/bot-link/_link.vue' /* webpackChunkName: "pages/bot-link/_link" */))
const _1f83a926 = () => interopDefault(import('../pages/confirm/_token.vue' /* webpackChunkName: "pages/confirm/_token" */))
const _0b585297 = () => interopDefault(import('../pages/countries/_.vue' /* webpackChunkName: "pages/countries/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/ru",
    components: {
      default: _f04d711c
    },
    name: "index___ru"
  }, {
    path: "/cn",
    components: {
      default: _f04d711c
    },
    name: "index___cn"
  }, {
    path: "/tr",
    components: {
      default: _f04d711c
    },
    name: "index___tr"
  }, {
    path: "/authorization",
    component: _4b2f90e2,
    pathToRegexpOptions: {"strict":true},
    name: "authorization"
  }, {
    path: "/blog",
    component: _8abc3abc,
    pathToRegexpOptions: {"strict":true},
    name: "blog"
  }, {
    path: "/partner",
    component: _7c5d255e,
    pathToRegexpOptions: {"strict":true},
    name: "partner"
  }, {
    path: "/profile",
    component: _0d08ecff,
    pathToRegexpOptions: {"strict":true},
    name: "profile"
  }, {
    path: "/registration",
    component: _702235f9,
    pathToRegexpOptions: {"strict":true},
    name: "registration"
  }, {
    path: "/request-password",
    component: _557916d9,
    pathToRegexpOptions: {"strict":true},
    name: "request-password"
  }, {
    path: "/search",
    component: _abc67830,
    pathToRegexpOptions: {"strict":true},
    name: "search"
  }, {
    path: "/success-payment",
    component: _5938fed2,
    pathToRegexpOptions: {"strict":true},
    name: "success-payment"
  }, {
    path: "/countries/ind222ex__",
    component: _ff95a704,
    pathToRegexpOptions: {"strict":true},
    name: "countries-ind222ex__"
  }, {
    path: "/marketplace/account",
    component: _3b41e93f,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account"
  }, {
    path: "/marketplace/registration",
    component: _070b7abd,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration"
  }, {
    path: "/partner/activations-history",
    component: _fca9d01a,
    pathToRegexpOptions: {"strict":true},
    name: "partner-activations-history"
  }, {
    path: "/partner/price-regulator",
    component: _fd1b5768,
    pathToRegexpOptions: {"strict":true},
    name: "partner-price-regulator"
  }, {
    path: "/partner/sim-card",
    component: _1058a56d,
    pathToRegexpOptions: {"strict":true},
    name: "partner-sim-card"
  }, {
    path: "/partner/statistic",
    component: _1ac04342,
    pathToRegexpOptions: {"strict":true},
    name: "partner-statistic"
  }, {
    path: "/partner/withdrawal-funds",
    component: _a2376b46,
    pathToRegexpOptions: {"strict":true},
    name: "partner-withdrawal-funds"
  }, {
    path: "/profile/history",
    component: _024cef78,
    pathToRegexpOptions: {"strict":true},
    name: "profile-history"
  }, {
    path: "/profile/loyalty-program",
    component: _4270ef6d,
    pathToRegexpOptions: {"strict":true},
    name: "profile-loyalty-program"
  }, {
    path: "/profile/pay",
    component: _c9034450,
    pathToRegexpOptions: {"strict":true},
    name: "profile-pay"
  }, {
    path: "/profile/referral-program",
    component: _09f9307a,
    pathToRegexpOptions: {"strict":true},
    name: "profile-referral-program"
  }, {
    path: "/profile/sale-agreement",
    component: _ff175a2c,
    pathToRegexpOptions: {"strict":true},
    name: "profile-sale-agreement"
  }, {
    path: "/profile/settings",
    component: _69fb6089,
    pathToRegexpOptions: {"strict":true},
    name: "profile-settings"
  }, {
    path: "/profile/statistics",
    component: _4247fec9,
    pathToRegexpOptions: {"strict":true},
    name: "profile-statistics"
  }, {
    path: "/profile/tariffs",
    component: _6f92c1bf,
    pathToRegexpOptions: {"strict":true},
    name: "profile-tariffs"
  }, {
    path: "/signup/confirm-email",
    component: _b9df0756,
    pathToRegexpOptions: {"strict":true},
    name: "signup-confirm-email"
  }, {
    path: "/marketplace/account/adding-product",
    component: _4c3b72a9,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-adding-product"
  }, {
    path: "/marketplace/account/balance",
    component: _92aed668,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-account-balance"
  }, {
    path: "/marketplace/registration/originalIndex",
    component: _873aee28,
    pathToRegexpOptions: {"strict":true},
    name: "marketplace-registration-originalIndex"
  }, {
    path: "/blog/:slug",
    component: _8e297d4c,
    pathToRegexpOptions: {"strict":true},
    name: "blog-slug"
  }, {
    path: "/bot-link/:link?",
    component: _492b07c7,
    pathToRegexpOptions: {"strict":true},
    name: "bot-link-link"
  }, {
    path: "/confirm/:token?",
    component: _1f83a926,
    pathToRegexpOptions: {"strict":true},
    name: "confirm-token"
  }, {
    path: "/countries/*",
    component: _0b585297,
    pathToRegexpOptions: {"strict":true},
    name: "countries-all"
  }, {
    path: "/*",
    component: _f04d711c,
    pathToRegexpOptions: {"strict":true},
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
